.containerC {
  background-color: rgb(214, 205, 194);
  width: 50%;
  margin-bottom: 40px;
  margin-left: 25%;
  text-align: center;
}
.Calc {
  width: 30%;
  height: 75%;
  position: absolute;
  top: 15%;
  left: 35%;
  border: none;
  border-radius: 10px;
  background-color: #31384b;
  box-shadow: 10px 10px 5px #c4c6c9;
}
.calculator-screen {
  border-bottom: 1px solid #484d5a;
  width: 98%;
  height: 60px;
  background-color: #31384b;
  border-radius: 10px 10px 0 0;
  font-size: 3.5rem;
  color: #fff;
  text-align: right;
}

button {
  height: 60px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #c4c4c4;
  background-color: transparent;
  font-size: 2rem;
  color: #333;
  background-image: linear-gradient(
    to bottom,
    transparent,
    transparent 50%,
    rgba(0, 0, 0, 0.04)
  );
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.45),
    inset 0 -1px 0 0 rgba(255, 255, 255, 0.15),
    0 1px 0 0 rgba(255, 255, 255, 0.15);
  text-shadow: 0 1px rgba(255, 255, 255, 0.4);
}

button:hover {
  background-color: #eaeaea;
}

.operator {
  color: #337cac;
}

.all-clear {
  background-color: #f0595f;
  border-color: #b0353a;
  color: #fff;
}

.all-clear:hover {
  background-color: #f17377;
}

.equal-sign {
  background-color: #2e86c0;
  border-color: #337cac;
  color: #fff;
  height: 100%;
  grid-area: 2 / 4 / 6 / 5;
}

.equal-sign:hover {
  background-color: #4e9ed4;
}

.calculator-keys {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 20px;
}
@media only screen and (max-width: 600px) {
  .Calc {
    width: 100%;
    height: 100%;
    left: 0;
  }
  button {
    height: 60px;
  }
  .containerC {
    margin-left: 0;
    width: 100%;
  }
  h5 {
    color: #681753;
  }
}
